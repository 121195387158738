<template>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card class="cardClassDetailForm" title="안전활동 목표 정보">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable && param.safetyActionTargetId"
                  label="LBLREMOVE"
                  icon="delete_forever"/>
                <c-btn
                  v-if="editable"
                  label="LBLSAVE"
                  icon="save"/>
                <c-btn
                  v-if="editable && param.safetyActionTargetId"
                  label="계획 완료"
                  color="purple-6"
                  icon="save"/>
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-2">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-2">
                <c-datepicker
                  :required="true"
                  :editable="editable"
                  label="목표년도"
                  name="year"
                  type="year"
                  default="today"
                  v-model="data.year"
                />
              </div>
              <div class="col-2">
                <c-select
                  :required="true"
                  :editable="editable"
                  type="edit"
                  codeGroupCd="SAFETY_ACTION_TARGET_TYPE_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="분야"
                  name="safetyActionTargetTypeCd"
                  v-model="data.safetyActionTargetTypeCd">
                </c-select>
              </div>
              <div class="col-5">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="사업장 목표"
                  name="targetTitle"
                  v-model="data.targetTitle">
                </c-text>
              </div>
              <div class="col-1">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="table"
          title="안전활동 세부 목표"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          :expandAll="true"
          :hideBottom="true"
          :isFullScreen="false"
          :filtering="false"
          :usePaging="false"
          :isExcelDown="false"
          @rowClick="rowClick"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <q-form ref="editForm2">
          <c-card class="cardClassDetailForm" title="안전활동 세부 목표 상세">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn label="LBLREG" icon="add" v-if="editable && param.safetyActionTargetId" />
                <c-btn label="LBLREMOVE" icon="remove" v-if="editable && param.safetyActionTargetId" />
                <c-btn label="LBLSAVE" icon="save" v-if="editable && param.safetyActionTargetId" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :required="true"
                  :editable="editable && dataeditable"
                  label="세부 목표"
                  name="detailTitle"
                  v-model="detailData.detailTitle">
                </c-text>
              </div>
              <div class="col-12">
                <c-textarea
                  :editable="editable && dataeditable"
                  :rows="2"
                  label="추진 계획"
                  name="detailPlan"
                  v-model="detailData.detailPlan">
                </c-textarea>
              </div>
              <div class="col-10">
                <c-text
                  :editable="editable && dataeditable"
                  label="1분기 계획 상세"
                  name="quater1Plan"
                  v-model="detailData.quater1Plan">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :editable="editable && dataeditable"
                  label="1분기 목표"
                  suffix="%"
                  type="number"
                  name="quater1"
                  v-model="detailData.quater1">
                </c-text>
              </div>
              <div class="col-10">
                <c-text
                  :editable="editable && dataeditable"
                  label="2분기 계획 상세"
                  name="quater2Plan"
                  v-model="detailData.quater2Plan">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :editable="editable && dataeditable"
                  label="2분기 목표"
                  suffix="%"
                  type="number"
                  name="quater2"
                  v-model="detailData.quater2">
                </c-text>
              </div>
              <div class="col-10">
                <c-text
                  :editable="editable && dataeditable"
                  label="3분기 계획 상세"
                  name="quater3Plan"
                  v-model="detailData.quater3Plan">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :editable="editable && dataeditable"
                  label="3분기 목표"
                  suffix="%"
                  type="number"
                  name="quater3"
                  v-model="detailData.quater3">
                </c-text>
              </div>
              <div class="col-10">
                <c-text
                  :editable="editable && dataeditable"
                  label="4분기 계획 상세"
                  name="quater4Plan"
                  v-model="detailData.quater4Plan">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :editable="editable && dataeditable"
                  label="4분기 목표"
                  suffix="%"
                  type="number"
                  name="quater4"
                  v-model="detailData.quater4">
                </c-text>
              </div>
              <div class="col-6">
                <c-field
                  :required="true"
                  :editable="editable && dataeditable"
                  :data="detailData"
                  deptValue="deptCd"
                  type="dept_user"
                  label="담당부서 & 담당자"
                  name="userId"
                  v-model="detailData.userId">
                </c-field>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
</template>

<script>
export default {
  name: 'safety-action-target-detail-plan',
  props: {
    param: {
      type: Object,
      default: () => ({
        safetyActionTargetId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      dataeditable: false,
      grid: {
        columns: [
          {
            name: 'detailTitle',
            field: 'detailTitle',
            label: '세부 목표',
            align: 'left',
            sortable: true,
          },
          {
            label: '분기별 목표 (%)',
            align: 'center',
            child: [
              {
                name: 'quater1',
                field: 'quater1',
                label: '1분기',
                align: 'center',
                style: 'width:80px',
                sortable: false,
              },
              {
                name: 'quater2',
                field: 'quater2',
                label: '2분기',
                align: 'center',
                style: 'width:80px',
                sortable: false,
              },
              {
                name: 'quater3',
                field: 'quater3',
                label: '3분기',
                align: 'center',
                style: 'width:80px',
                sortable: false,
              },
              {
                name: 'quater4',
                field: 'quater4',
                label: '4분기',
                align: 'center',
                style: 'width:80px',
                sortable: false,
              },
            ]
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '담당 부서',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: 'LBLMANAGER',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
        ],
        data: [],
        height: '',
      },
      data: {
        plantCd: null,
        year: '',
        safetyActionTargetTypeCd: null,
        targetTitle: '',
        useFlag: 'Y',
      },
      detailData: {
        detailTitle: '',
        detailPlan: '',
        quater1: '',
        quater2: '',
        quater3: '',
        quater4: '',
        quater1Plan: '',
        quater2Plan: '',
        quater3Plan: '',
        quater4Plan: '',
        deptName: '',
        userName: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.grid.height = (window.innerHeight - 300) + 'px';
      this.info();
      this.getList();
    },
    info() {
      this.data = {
        plantCd: '1000',
        year: '2022',
        safetyActionTargetTypeCd: 'SATT000002',
        targetTitle: '2022년도 환경활동 목표',
        useFlag: 'Y',
      }
    },
    getList() {
      this.grid.data = [
        {
          detailTitle: '환경 순회점검 주 2회 실시',
          quater1: '25%',
          quater2: '25%',
          quater3: '25%',
          quater4: '25%',
          deptName: '환경팀',
          userName: '홍길동',
        },
        {
          detailTitle: '대기 자가측정 월 2회 실시',
          quater1: '25%',
          quater2: '25%',
          quater3: '25%',
          quater4: '25%',
          deptName: '환경팀',
          userName: '홍길동',
        },
      ];
    },
    rowClick() {
      this.dataeditable = true;
      this.detailData = {
        detailTitle: '환경 순회점검 주 2회 실시',
        detailPlan: '상세 세부 추진 계획',
        quater1: '25',
        quater2: '25',
        quater3: '25',
        quater4: '25',
        quater1Plan: '1분기 계획',
        quater2Plan: '2분기 계획',
        quater3Plan: '3분기 계획',
        quater4Plan: '4분기 계획',
        deptName: '환경팀',
        userName: '홍길동',
        userId: 'U000000001',
        deptCd: 'D10005',
      }
    },
  }
};
</script>
